import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { SessionContext } from "../SessionContext.tsx";
import { getServer } from "../Server.tsx";

export function GamePanel({ }){
	return (
			<div><_GamePanel /></div>
	);
}

export class _GamePanel extends Component {
	static contextType = SessionContext;
	
	state = {
		items: null
	};

	componentDidMount() {
		let that = this;
		this.load();
	}

	addProduct(id) {
		ProgressiveRequest({
			url: getServer()+'/api/front/basket',
			method: 'post',
			data: {
				action: 'add',
				id: id,
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				//setBasket([...id]);
			},
			received: function(){
				console.log('done');
			}
		});
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		console.log("session", session);
		ProgressiveRequest({
			url: getServer()+'/api/front/games',
			method: 'post',
			data: {
				action: 'get'
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					items: data.list,
					token: data.token
				});
			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}
	
	render() {
		let that = this;

		/*
		<div class="row">
				<img class="col w-50" src="/games/cash.jpeg"/>
				<div class="col">
					<h3>Cash</h3>
					<p>Waw</p>
					<a href="#" onClick={() => this.addProduct(23)} class="btn bg-success text-white">Commander</a>
				</div>
			</div>
			*/
		return (<div>
			{that.state.items && that.state.items.map((e) => {
				return <div class="my-3">
					<a href={"/jeux/"+e.attributes.alias} class="a-reset">
						<div style={{ backgroundImage: 'url("'+e.attributes.bgImg+'")', backgroundSize: 'contain', height: '170px' }} class="img-fluid crounded-1 d-flex flex-column">
							<div class="px-2 pt-2 d-flex justify-content-between align-items-center">
								<img
									src="/illiko.png"
									class="w-14"
									alt="Illiko"
								/>
								<div class="text-end"><span class="badge rounded-pill bg-light titleb">{e.attributes.price ? e.attributes.price+' €': ''}</span></div>
							</div>
							<div class="d-flex justify-content-center align-items-center">
								<img style={{maxHeight: "80%", maxWidth: "80%"}} src={e.attributes.fgImg} class="" alt={e.attributes.name} />
							</div>
							<div class="mt-auto mx-2 pb-2">
								<div class="txt-white m-0 fw-bold fs-5 lh-sm">{e.attributes.name}</div>
								<div class="txt-white m-0 mb-1 lh-sm">Jusqu'à {e.attributes.awards ? e.attributes.awards+' €': ''}</div>
							</div>
						</div>
					</a>
				</div>;
			})}
			{/*that.state.items && that.state.items.map((e) => {
				return <div class="card mb-3 crounded-1 border-0 cshadow">
					<div class="row g-0">
						<div class="col-4">
							<img src={e.attributes.img} class="img-fluid crounded-1-start" alt={e.attributes.name} />
						</div>
						<div class="col-8">
							<div class="card-body">
								<div className="d-flex justify-content-between align-items-center mb-1">
									<h5 class="card-title titleb m-0">{e.attributes.name}</h5>
									<h5 class="card-title titleb m-0">{e.attributes.price ? e.attributes.price+'€': ''}</h5>
								</div>
								<p class="card-text m-0 mb-3 lh-1" style="font-size: 0.8rem;">{e.attributes.baseline}</p>
								<a href={"/jeux/"+e.attributes.alias} class="btn btn-b rounded-pill px-4 py-0 float-end">Acheter</a>
							</div>
						</div>
					</div>
				</div>;
			})*/}
			{/*
			<div class="card mb-3" style="max-width: 540px;">
			<div class="row g-0">
			  <div class="col-md-4">
				<img src="/games/cash.jpeg" class="img-fluid rounded-start" alt="cash" />
			  </div>
			  <div class="col-md-8">
				<div class="card-body">
				  <h5 class="card-title m-0">Cash</h5>
				  <p class="card-text m-0" style="font-size: 0.8rem;">Tentez votre chance de remporter jusqu'à 500 000€ !</p>
				  <a href={"/game/1"} class="btn btn-b rounded-pill px-3 float-end">Acheter</a>
				</div>
			  </div>
			</div>
			</div>*/}
			</div>
		);
	}
}
